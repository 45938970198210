<template>
  <div>
    <div class="logo">
      <img src="../assets/logo.png" alt="kesseo" />
      <p>kesseo</p>
    </div>
    <div id="particle"></div>
    <VueParticle domId="particle" :config="particleConfig" />
  </div>
</template>

<script>
import VueParticle from "vue-particlejs";

export default {
  components: {
    VueParticle,
  },
  data() {
    return {
      particleConfig: {
        particles: {
          number: {
            value: 4,
            density: {
              enable: true,
              value_area: 200,
            },
          },
          color: {
            value: "#041d35",
          },
          shape: {
            type: "polygon",
            stroke: {
              width: 0,
              color: "#041d35",
            },
            polygon: {
              nb_sides: 6,
            },
          },
          opacity: {
            value: 0.2,
            random: true,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 100,
            random: true,
            anim: {
              enable: true,
              speed: 5,
              size_min: 1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 350,
            color: "#041d35",
            opacity: 0.2,
            width: 2,
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "grab",
            },
            onclick: {
              enable: false,
              mode: "grab",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      },
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Scada&display=swap");

html {
  background: #041d35;
  background: radial-gradient(circle farthest-corner, #113860 0%, #041d35 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  text-align: center;
}
body {
  overscroll-behavior: none;
}
.logo {
  font-size: 1.2rem;
}
img {
  filter: brightness(0);
  margin-left: -0.3em;
  width: 4em;
}
p {
  font-family: Scada, Avenir, sans-serif;
  color: black;
  font-size: 2.5em;
  margin: 0;
  line-height: 1;
}
img,
p {
  user-select: none;
  user-drag: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  opacity: 0.15;
  transition: all 0.5s;
  &:hover {
    opacity: 0.3;
  }
}
.particles-js-canvas-el {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
</style>
